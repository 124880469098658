import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderTopbar2 from '../HeaderTopbar2/HeaderTopbar2';
import MobileMenu from '../MobileMenu/MobileMenu';
import Logo from '../../images/logo-2.png';
import { clearLocalData, getLocalDataAsObject } from '../../utils/CoustomStorage';

const Header3 = (props) => {
  const push = useNavigate();

  const [menuActive, setMenuState] = useState(false);
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(0, 0);
  };
  const Logout = () => {
    push('/');
    clearLocalData();
    window.location.reload();
  };

  return (
    <header id='header' className={`wpo-header-style-7 ${props.topbarNone}`}>
      <HeaderTopbar2 />
      <div style={{ background: 'rgb(231 231 232)' }} className={`wpo-site-header ${props.hclass}`}>
        <nav style={{ background: 'rgb(231 231 232)' }} className='navigation navbar navbar-expand-lg navbar-light'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-3 col-3 d-lg-none dl-block'>
                <div className='mobail-menu'>
                  <MobileMenu />
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-6'>
                <div className='navbar-header'>
                  <Link onClick={ClickHandler} className='navbar-brand' to='/home'>
                    <img src='img/MdfcCardLogo1.png' style={{ height: '70px', width: '70', borderRadius: '50%' }}></img>
                  </Link>
                </div>
              </div>
              <div className='col-lg-6 col-md-1 col-1'>
                <div id='navbar' className='collapse navbar-collapse navigation-holder'>
                  <button className='menu-close'>
                    <i className='ti-close'></i>
                  </button>
                  <ul className='nav navbar-nav mb-2 mb-lg-0'>
                    <li className='menu-item-has-children'>
                      <Link onClick={ClickHandler} to='/'>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to='/about'>
                        About Us
                      </Link>
                    </li>
                    <li className='menu-item-has-children'>
                      <Link to='/service'>Services</Link>
                    </li>
                    <li className='menu-item-has-children'>
                      <Link to='/service'>Project</Link>
                    </li>

                    <li>
                      <Link onClick={ClickHandler} to='/contact'>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3 col-md-2 col-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <div className='close-form'>
                    <div onClick={getLocalDataAsObject('token') ? Logout : ''} className='log_out_btn'>
                      {getLocalDataAsObject('token') ? 'Logout' : 'Apply Now'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header3;
