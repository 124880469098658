import React, { Fragment, useContext, useEffect, useState } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png';
import UserContext from '../../components/ContextApi/UserContext';
import UserDetails from './UserDetails';
import HeaderSection from './HeaderSection';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import { toast } from 'react-toastify';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Navbar5 from '../../components/Navbar5/Navbar5';
import Swal from 'sweetalert2';
import { clearLocalData, getLocalDataAsObject } from '../../utils/CoustomStorage';

const AdminDashboard = () => {
  const api = new API();
  const [allUserDetails, setAllUserDetails] = useState([]);
  const [token, setToken] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState('');

  useEffect(() => {
    let token = getLocalDataAsObject('admin-token');
    if (!token) {
      clearLocalData();
      return window.location.replace('/');
    }
    setToken(true);
  }, []);

  useEffect(() => {
    getAllUserDetails();
  }, [page, pageSize, search]);

  // const { user } = useContext(UserContext);

  const getAllUserDetails = async () => {
    let params = {
      page: page,
      pageSize: pageSize,
      search: search,
      mobileSearch: search,
    };
    const response = await api.get(config.getUserList, true, params);
    console.log('response is', response);
    if (response.code == 200) {
      setAllUserDetails(response?.data);
      console.log(response);
    } else {
      toast.success('Something wen wrong !');
    }
  };
  const updateUserDetails = async (user, key, value) => {
    let data = {
      status: value,
      statusChangeFor: key,
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to update this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.put(`${config.changeStatus}/${user._id}`, data);
        if (response.code == 200) {
          Swal.fire({
            title: 'Updated!',
            text: 'Successfully updated.',
            icon: 'success',
          });
          getAllUserDetails();
          console.log(response);
        } else {
          toast.success('Something wen wrong !');
        }
      }
    });
  };
  const deleteUser = (item) => {
    let data = {
      userId: item._id,
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.post(config.deleteAccount, data);
        if (response.code == 200) {
          Swal.fire({
            title: 'Delete!',
            text: 'Successfully deleted.',
            icon: 'success',
          });
          getAllUserDetails();
          console.log(response);
        } else {
          toast.success('Something wen wrong !');
        }
      }
    });
  };
  return (
    <Fragment>
      {token && (
        <Fragment>
          <Navbar5 Logo={Logo} />
          {/* <PageTitle pageTitle={'Admin Dashboard'} pagesub={'Admin-Dashboard'}/>  */}
          <div>
            <h1 align='center'>MdfcCard Admin Dashboard</h1>
            <div style={{ width: '80%', margin: 'auto', marginTop: '3%' }}>
              <HeaderSection />
              <div style={{ marginTop: '5%' }}>
                <UserDetails
                  allUserDetails={allUserDetails}
                  updateUserDetails={updateUserDetails}
                  deleteUser={deleteUser}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  setSearch={setSearch}
                  search={search}
                />
              </div>
            </div>
          </div>
          {/* <Footer2/> */}
          <Scrollbar />
        </Fragment>
      )}
    </Fragment>
  );
};
export default AdminDashboard;
