import React from 'react';
import IdentityForm from '../ContactFrom/identityForm';

const IdentityInformation = ({ steperChange }) => {
  return (
    <section className='wpo-contact-pg-section section-padding' style={{ padding: '30px' }}>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-10 offset-lg-1'>
            <div
              className='wpo-contact-form-area apply_contact_form'
              style={{
                backgroundImage:
                  'linear-gradient(90deg, rgba(82,59,119,1) 0%, rgba(121,98,158,1) 50%, rgba(83,50,107,1) 100%)',
                backgroundSize: 'contain',
                marginBottom: '0',
                borderRadius: '50px',
              }}
            >
              <IdentityForm steperChange={steperChange} />
            </div>
            <div className='office-info'>
              <div className='row'></div>
            </div>
            <div className='wpo-contact-title'>
              <h2>Have Any Question?</h2>
              <p>It is a long established fact that a reader will be distracted content of a page when looking.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IdentityInformation;
